html,
body {
    font-size: 16px;
    color: #353535;
    text-align: left;
    font-family: "Barlow", sans-serif;
}

/* .today {
    color: green;

    & a {
        color: red;

        & span {
         color: purple;
        }
    }
} */

h1 {
    font-size: clamp(2.7rem, 2.57rem + 0.6501vw, 3.125rem);
}

h2 {
    font-size: 1.875rem;
}

label > input[type="radio"] {
    display: none;
}

label > input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: gray;
}

label > input[type="radio"]:checked + * {
    color: #10995D;
    font-weight: 600;
}

label > input[type="radio"]:checked + *::before {
    background: radial-gradient(#10995D 0%, #10995D 40%, transparent 50%, transparent);
    border-color: #10995D;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: 0px;
}

select {
    font-size: clamp(0.625rem, 0.5868rem + 0.1912vw, 0.75rem) !important;
}

.bg-hover-outline:hover {
    background-color: transparent;
    border: 2px solid #10995d;
    border-radius: 62.5rem;
    color: #10995d;
    letter-spacing: 1.8px;
    transition: background-color 0.4s ease;
}

.bg-hover-green:hover {
    background-color: #10995d;
    border: 2px solid #10995d;
    border-radius: 62.5rem;
    color: #ffffff;
    letter-spacing: 1.8px;
    transition: background-color 0.4s ease;
}

.bg-hover-green:hover {
    background-color: #10995d;
    border: 2px solid #10995d;
    border-radius: 62.5rem;
    color: #ffffff;
    letter-spacing: 1.8px;
    transition: background-color 0.4s ease;
}

/*.blog-img-card {
    max-height: 100%;
}

.blog-img-card img {
    height: 100%;
    width: 100%;
}*/

.card-grid {
    margin: 0px auto;
    max-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

.card-grid2 {
    margin: 0px auto;
    max-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

.card-grid-doc {
    margin:3rem auto;
    max-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 2.25rem;
}

.card-grid-recruitment {
    margin: 3.125rem auto;
    max-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 5rem;
}

.card-grid-small {
    margin: 0px auto;
    max-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

.card-grid-blog {
    margin: 0px auto;
    max-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

.card-grid-int {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 4.125rem;
}

.card-grid-int-small {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 0.125rem;
}

.card-grid-int-blog {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 0.125rem;
    cursor: pointer;
    transition: all 0.1s;
    
}
.card-grid-int-blog:hover {
    transform: scale(0.99);
    transition: all 0.1s;
}
.card-grid-int-blog:hover h2 {
    color: #10995D;
}

.img-background {
    background-image: linear-gradient(90deg, #ffffff 0%, #ffffffb3 100%),
        url(/dist/img/servicios/fondo-servicios.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}

.img-background-header {
    background-image: linear-gradient(
            180deg,
            #ffffff 0%,
            #ffffffe6 8%,
            #ffffff00 100%
        ),
        url(/dist/img/header/header-home.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}

.img-background-header-page2 {
    background-image: linear-gradient(
            180deg,
            #ffffff 0%,
            #ffffffe6 8%,
            #ffffffdb 100%
        ),
        url(/dist/img/header/header-caracteristicas.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}

.img-background-header-page3 {
    background-image: linear-gradient(
            180deg,
            #ffffff 0%,
            #ffffffe6 8%,
            #ffffffdb 100%
        ),
        url(/dist/img/header/header-servicios.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}

.img-background-textura-lva {
    background: url(/dist/img/lva/textura-lva.svg) no-repeat left
        center;
    background-color: #f5f5f5;
    background-size: 30.156rem 37.738rem;
    background-position: -8.625rem;
}

.img-background-us {
    background: url(/dist/img/identidad/textura-huella.svg)
        no-repeat center right;
    background-color: #ffffff;
    background-size: 34.5rem 33.066rem;
    background-position: center right -127px;
}

input::placeholder {
    color: #cbcbcb;
    font-weight: 400;
    font-size: 0.938rem;
}

.privacity-link {
    text-decoration: underline;

    &:hover {
        color: #353535;
        transition: color 0.4s ease;
    }
}

.link-under-green:hover {
    color: #10995D;
    transition: color 0.4s ease;
}

.link-under:hover {
    color: #353535;
    transition: color 0.4s ease;
}

.link-contact {
    /*margin: 0px auto;
    max-width: 320px;*/
}

.link-outline {
    & .child-one-outline:hover {
        background-color: #10995d;
        border: 2px solid #10995d;
        border-radius: 3.75rem;
        color: #ffffff;
        transition: background-color 0.4s ease;
    }

    & .child-two-outline:hover {
        color: #353535;
        transition: color 0.4s ease;
    }
}

.list-spacing {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;
}

.select-option {
    color: #353535;
    font-weight: 500;
    font-size: 0.938rem;
    cursor: pointer;
}

select {
    background-image: url(/dist/img/iconos/down-chevron.svg);
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}

.select-price {
    font-size: 18px !important;
}

.text-link-input {
    font-size: clamp(1rem, 0.9618rem + 0.1912vw, 1.125rem);
}

.text-area {
    resize: none !important;
}

@media only screen and (max-width: 599px) {
    .menu-mobile {
        top: 6rem !important;
    }

    .text-link-input {
        word-break: break-all;
    }
}

@media only screen and (min-width: 768px) {
    .card-grid {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 4rem;
    }
    .card-grid2 {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 4rem;
    }
    .card-grid-recruitment {
        margin: 3.125rem auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        gap: 5rem;
    }

    .card-grid-small {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .card-grid-blog {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .text-c-check {
        min-height: 128px;
    }
}

@media only screen and (min-width: 960px) {
    .form-contact-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1rem;
    }
    .card-grid-doc {
        margin:3rem auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        gap: 2.25rem;
    }

    .card-grid-doc-second {
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        gap: 2.25rem;
    }

    .h-card-doc {
        min-height: 33rem;
    }
}

@media only screen and (min-width: 960px) and (max-width: 993px) {
    .h-text-clas {
        min-height: 6rem;
    }
}

@media only screen and (min-width: 994px) and (max-width: 1024px) {
    .h-text-clas {
        min-height: 5rem;
    }
}

@media only screen and (min-width: 1024px) {
    .card-grid-doc {
        margin:3rem auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        gap: 2.25rem;
    }
    .card-grid-small {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .card-grid-blog {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .h-text-clas {
        min-height: 6rem;
    }

    .menu-text {
        font-size: clamp(0.9rem, 0.2263rem + 1.0526vw, 1.125rem);
    }
}

@media only screen and (max-width: 1023px) {
    .menu-mobile {
        position: absolute;
        /*left: 0;*/
        width: 320px;
        right: 2%;
        top: 8rem;
    }

    .link-under-nav {
        position: relative !important;

        &:after {
            background: #10995D !important;
            border-radius: 62.5rem !important;
            bottom: 10% !important;
            height: 0.375rem !important;
            content: "" !important;
            left: 0% !important;
            position: absolute !important;
            transform: translate(0%, 0) !important;
            transition: width 0.2s ease !important;
            width: 0% !important;
        }

        &:hover,
        &.--navlink-active {
            color: #10995D !important;
            &:after {
                width: 100% !important;
            }
        }
    }

    .navbar-default-in {
        animation-duration: 1s;
        animation-name: slidein;
    }

    .navbar-default-out {
        animation-duration: 1s;
        animation-name: slideout;
    }

    @keyframes slidein {
        from {
          right: 0%;
          width: 0%;
        }
        to {
          right: 0%;
          width: 100%;
        }
    }

    @keyframes slideout {
        from {
          right: 0%;
          width: 100%;
        }
        to {
          right: 0%;
          width: 0%;
        }
    }
}
@media only screen and (min-width: 1200px) {
    .card-grid {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 4rem;
    }
    .card-grid2 {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 4rem;
    }
    .card-grid-recruitment {
        margin: 3.125rem auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        gap: 5rem;
    }
    .card-grid-small {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .card-grid-blog {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .form-contact-container {
        display: grid;
        grid-template-columns: 42.25rem 1fr;
        grid-column-gap: 3rem;
    }
}
@media only screen and (min-width: 1026px){
    .h-text-clas {
        min-height: 5rem;
    }
}
@media only screen and (min-width: 1366px) {
    .card-grid {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 4rem;
    }
    .card-grid2 {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 4rem;
    }

    .card-grid-doc {
        margin:3rem auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        gap: 2.25rem;
    }

    .card-grid-recruitment {
        margin: 3.125rem auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        gap: 5rem;
    }

    .card-grid-small {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }
    .card-grid-blog {
        margin: 0px auto;
        max-width: 1366px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 1.438rem;
    }

    .form-contact-container {
        display: grid;
        grid-template-columns: 42.25rem 1fr;
        grid-column-gap: 5rem;
    }

    .h-text-clas {
        min-height: 0rem;
    }

    .h-card-doc {
        min-height: 31.438rem;
    }
}


.link-under-nav {
    position: relative;

    &:after {
        background: #10995D;
        border-radius: 62.5rem;
        bottom: 10%;
        height: 0.375rem;
        content: "";
        left: 45%;
        position: absolute;
        transform: translate(-45%, 0);
        transition: width 0.2s ease;
        width: 0%;
    }

    &:hover,
    &.--navlink-active {
        color: #10995D;
        &:after {
            width: 85%;
        }
    }
}

.post-list ul {
    list-style: revert;
    margin: revert;
    padding: revert;
}
